export const languages = {
  standard: ['English', 'Malay', 'Mandarin', 'Tamil'],
  chinese: [
    'Cantonese',
    'Foochow',
    'Hakka',
    'Hainanese',
    'Hokkien',
    'Min Bei',
    'Teochew',
  ],
  indian: [
    'Bengali',
    'Gujarati',
    'Hindi',
    'Malayalam',
    'Punjabi',
    'Telugu',
    'Urdu',
  ],
  malay: [
    'Malay - Brunei',
    'Malay - Kedah',
    'Malay - Kelantan',
    'Malay - Perak',
    'Malay - Negeri Sembilan',
    'Malay - Sabah',
    'Malay - Sarawak',
    'Malay - Terengganu',
  ],
  native: [
    'Bajaw',
    'Dusun',
    'Iban',
    'Javanese',
    'Melanau',
    'Minangkabau',
    'Tausug',
  ],
  foreign: [
    'Arabic',
    'Burmese',
    'Filipino',
    'Indonesian',
    'Japanese',
    'Khmer',
    'Korean',
    'Lao',
    'Nepali',
    'Thai',
    'Vietnamese',
  ],
};
