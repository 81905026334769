export const gender = [
  {
    key: 'M',
    name: 'Male',
  },
  {
    key: 'F',
    name: 'Female',
  },
  {
    key: 'O',
    name: 'Other',
  },
];

export const role = [
  {
    key: 'Actor',
    name: 'Actor / Actress',
  },
  {
    key: 'Commercial Talent',
    name: 'Commercial Talent',
  },
  {
    key: 'Model',
    name: 'Model',
  },
  {
    key: 'Influencer',
    name: 'Influencer',
  },
  {
    key: 'KOL',
    name: 'Key Opinion Leader (KOL)',
  },
  {
    key: 'Dancer',
    name: 'Dancer',
  },
  {
    key: 'Artist',
    name: 'Artist',
  },
  {
    key: 'Musician',
    name: 'Musician',
  },
];

export const race = ['Malay', 'Chinese', 'Indian', 'Other', 'Mixed'];

export const ethnicity = [
  'Aborigin',
  'Asian',
  'Asian - Chinese',
  'Asian - Indian',
  'Asian - Malay',
  'Asian - Others',
  'Asian - Southeast',
  'Black - African',
  'Black American',
  'Black Others',
  'Caucasian',
  'Latino / Hispanic',
  'Pan-asian',
  'Mediterranean',
  'Middle-eastern',
  'Mixed',
];

export const bodyType = ['Slim', 'Medium', 'Muscular', 'Large', 'Very Large'];

export const eyeColor = [
  'Black',
  'Blue',
  'Brown',
  'Dark Brown',
  'Light Brown',
  'Hazel',
  'Grey',
  'Green',
  'Others',
];

export const hairColor = [
  'Black',
  'Black with some grey',
  'Blonde',
  'Brown',
  'Dark Brown',
  'Light Brown',
  'Red',
  'Grey',
  'Hijab',
  'White',
  'Auburn',
  'Salt and Pepper',
  'Strawberry Blonde',
  'Others',
];

export const shirtSize = ['XS', 'S', 'M', 'L', 'XL'];
